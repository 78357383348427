import * as React from "react"

export default function Page() {
    return (
        <div className="legal-page relative bg-darker py-24">
            <div className="container">
                <div className="w-full lg:w-2/3 mx-auto cl-50 z-ind">
                    <h1 className="text-white"><span className="cl-primary">Refund</span> &amp; Policy.</h1>
                    <h4>TERMINATIONS & REFUNDS</h4>
                    <ul>
                        <li>Either party may terminate the agreement with <strong>30 days’ written notice</strong>.</li>
                        <li>If the client cancels before project completion, payment is required for all completed work up to that point.</li>
                        <li><strong>Deposits are non-refundable</strong> as they secure the project and timeline.</li>
                        <li>Refunds, if applicable, are determined based on project milestones met.</li>
                    </ul>
                    <h4>INACTIVE PROJECTS</h4>
                    <ul>
                        <li>If a project remains inactive for more than <strong>6 weeks</strong> due to client delays, it will need to be rescheduled based on our availability.</li>
                        <li>Additional costs may apply for rescheduling.</li>
                    </ul>
                    <h4>HOURLY & RETAINER WORK</h4>
                    <ul>
                        <li><strong>Unused time does not roll over</strong> to the next month.</li>
                        <li>No refunds or credits are issued for expired or unused hours.</li>
                        <li>Time purchased is <strong>non-transferable</strong> between clients or projects.</li>
                    </ul>
                    <h4>HOSTING & DOMAIN SERVICES</h4>
                    <ul>
                        <li>Hosting and domain renewal fees are non-refundable once paid.</li>
                        <li>Cancellations must be requested before the next billing cycle to avoid additional charges.</li>
                    </ul>
                    <p>If you have any questions regarding refund policy, please contact us at <a href="mailto:info@artificial.studio">info@artificial.studio</a></p>
                </div>
            </div>
            <div className="top-gradient-dark"></div>
        </div>
    )
}
